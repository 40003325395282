import React from 'react'
import Card from '@material-ui/core/Card'
import CardActionArea from '@material-ui/core/CardActionArea'
import CardContent from '@material-ui/core/CardContent'
import Typography from '@material-ui/core/Typography'

import { withRouter } from 'react-router-dom'
import { withStyles } from '@material-ui/styles'

const NavCard = withStyles(() => ({
  root: {
    maxWidth: 230,
    margin: '5px',
    display: 'inline-block',
    background: '#ffffff',
    color: 'black'
  }
}))(Card)

class Home extends React.Component {
  constructor(props) {
    super(props)

    this.routeChange = this.routeChange.bind(this);
  }

  routeChange=(path)=> {
    this.props.history.push(path)
  }

  render () {
    if (this.props.loggedIn) {
      return (
        <div className='mainBody'>
          <center>
            <h1>Atomaki</h1>
          </center>
          <center>
            <NavCard>
              <CardActionArea onClick={() => this.routeChange('/ui/machines')}>
                <CardContent>
                  <Typography variant="h5" component="h4">Machines</Typography>
                  <Typography variant="body2" component="p">View Machines</Typography>
                </CardContent>
              </CardActionArea>
            </NavCard>
            <NavCard>
              <CardActionArea onClick={() => this.routeChange('/ui/profile')}>
                <CardContent>
                  <Typography variant="h5" component="h4">Profile</Typography>
                  <Typography variant="body2" component="p">View Profile</Typography>
                </CardContent>
              </CardActionArea>
            </NavCard>
          </center>
        </div>
      )
    } else {
      return (
        <div className='mainBody'>
          <center>
            <h1>Atomaki</h1>
          </center>
          <center>
            <p>Welcome to Atomaki</p>
          </center>
        </div>
      )
    }
  }
}

export default withRouter(Home)
