import React from 'react'
import Card from '@material-ui/core/Card'
import CardActionArea from '@material-ui/core/CardActionArea'
import CardContent from '@material-ui/core/CardContent'
import Typography from '@material-ui/core/Typography'
import { AddCircleOutlined, SettingsOutlined } from '@material-ui/icons'
import Tooltip from '@material-ui/core/Tooltip';

import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'
import TextField from '@material-ui/core/TextField'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import { PieChart, Pie, Cell } from 'recharts';

import { withRouter } from 'react-router-dom'
import { withStyles } from '@material-ui/styles'
import { withSnackbar  } from 'notistack'
import Colours from './Colours.js'

const StateCard = withStyles(() => ({
  root: {
    maxWidth: 230,
    margin: '5px',
    display: 'inline-block',
    background: Colours.navigationCards.background,
    color: 'white'
  }
}))(Card)
const StateCardSelected = withStyles(() => ({
  root: {
    maxWidth: 230,
    margin: '5px',
    display: 'inline-block',
    background: Colours.navigationCards.backgroundActive,
    color: 'white'
  }
}))(Card)

class Machine extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      addStateDialogOpen: false,
      addStateDialogStateName: '',
      addStateDialogStateDescription: '',
      machine: undefined,
      activeIndex: 0,
      lastDataTime: Date.now(),
      deleteMachineDialogOpen: false,
    }
    this.machineId = this.props.match.params.machineId
    this.atomakiAPIClient = props.utils.atomakiAPIClient
    this.enqueueSnackbar = props.enqueueSnackbar

    this.refreshData = this.refreshData.bind(this)
    this.routeChange = this.routeChange.bind(this)
    this.editMachinePage = this.editMachinePage.bind(this)
    this.addStateDialogOpen = this.addStateDialogOpen.bind(this)
    this.addStateDialogClose = this.addStateDialogClose.bind(this)
    this.addStateDialogAdd = this.addStateDialogAdd.bind(this)
    this.addStateDialogStateNameChange = this.addStateDialogStateNameChange.bind(this)
    this.addStateDialogStateDescriptionChange = this.addStateDialogStateDescriptionChange.bind(this)
    this.activateState = this.activateState.bind(this)
  }

  editMachinePage () {
    this.routeChange(`/ui/machines/${this.machineId}/config`)
  }

  addStateDialogOpen () {
    this.setState({ addStateDialogOpen: true })
  }

  addStateDialogClose () {
    this.setState({ addStateDialogOpen: false })
  }

  addStateDialogAdd (e) {
    if (!this.state.addStateDialogOpen ||
      this.state.addStateDialogStateName.length === 0 ||
      (e.keyCode && e.keyCode !== 10 && e.keyCode !== 13)) {
      return
    }

    e.stopPropagation()
    this.addStateDialogClose()
    const stateName = this.state.addStateDialogStateName
    const stateDescription = this.state.addStateDialogStateDescription
    this.atomakiAPIClient.createState(this.state.machine.id, stateName, stateDescription)
      .then(
        () => {
          this.refreshData()
          this.enqueueSnackbar('State ' + stateName + ' added', { variant: 'success' })
        },
        (err) => {
          this.enqueueSnackbar('Failed to add state ' + stateName, { variant: 'error' })
        })
      .then(() => {
        this.setState({ addStateDialogStateName: '' })
      })
  }

  addStateDialogStateNameChange (e) {
    this.setState({ addStateDialogStateName: e.target.value })
  }

  addStateDialogStateDescriptionChange (e) {
    this.setState({ addStateDialogStateDescription: e.target.value })
  }

  activateState(targetStateName, targetStateId) {
    this.atomakiAPIClient.createTransition(this.state.machine.id, targetStateId)
      .then(
        () => {
          this.refreshData()
          this.enqueueSnackbar('State ' + targetStateName + ' active', { variant: 'success' })
        },
        (err) => {
          this.enqueueSnackbar('Failed to change state to ' + targetStateName, { variant: 'error' })
        }
      )
  }

  refreshData () {
    this.atomakiAPIClient.getMachine(this.machineId)
      .then(machine => {
        const currentStateIndex = machine.states.findIndex(state => state.id === machine.currentState)
        this.setState({
          machine: machine,
          activeIndex: currentStateIndex,
          lastDataTime: Date.now(),
        })
      }).catch(() => {
        this.enqueueSnackbar('Failed to fetch machine data', { variant: 'error' })
        this.setState({
          machine: undefined,
          activeIndex: 0
        })
      })
  }

  componentDidMount () {
    this.refreshData()
    setInterval(() => {
      this.setState({
        lastDataTime: Date.now(),
      })
    }, 20000)
  }

  routeChange = path => {
    this.props.history.push(path)
  }

  render () {
    if (this.state.machine) {
      const currentState = this.state.machine.currentState
      const data = []
      let states = [<Tooltip title="Add" key="add-new-state-tooltip" arrow>
        <StateCard key="add-new-state">
          <CardActionArea onClick={this.addStateDialogOpen}>
            <CardContent>
              <AddCircleOutlined />
            </CardContent>
          </CardActionArea>
        </StateCard>
      </Tooltip>,
      <br key="add-new-state-br" />]
      let dataMapIndex = -1
      this.state.machine.states.forEach(state => {
        const extraTimeMS = Date.now() - this.state.machine.lastStateChangeTime
        if (state.name !== 'Off') {
          if (state.id === currentState) {
            data.push({ name: state.name, value: state.timeInStateMS + extraTimeMS})
          } else {
            data.push({ name: state.name, value: state.timeInStateMS})
          }
        }
        if (state.id === currentState) {
          if (state.name !== 'Off') {
            dataMapIndex = data.length - 1
          }
          states.push(<Tooltip title={state.id} arrow key={state.id}>
            <StateCardSelected>
              <CardActionArea onClick={() => {this.activateState(state.name, state.id)}}>
                <CardContent>
                  <Typography variant="h5" component="h5">{state.name}</Typography>
                  <Typography variant="body2" component="p">{state.description}</Typography>
                </CardContent>
              </CardActionArea>
            </StateCardSelected>
          </Tooltip>)
          states.push(<span key={`${state.id}-span`}>{state.timeInStateMS + extraTimeMS}ms</span>)
          states.push(<br key={`${state.id}-br`}/>)
        } else {
          states.push(<Tooltip title={state.id} arrow key={state.id}>
            <StateCard>
              <CardActionArea onClick={() => {this.activateState(state.name, state.id)}}>
                <CardContent>
                  <Typography variant="h5" component="h5">{state.name}</Typography>
                  <Typography variant="body2" component="p">{state.description}</Typography>
                </CardContent>
              </CardActionArea>
            </StateCard>
          </Tooltip>)
          states.push(<span key={`${state.id}-span`}>{state.timeInStateMS}ms</span>)
          states.push(<br key={`${state.id}-br`}/>)
        }
      })

      const splitDivs = {
        display: 'inline-block',
        width: '50%',
      }
      return (
        <div className='mainBody'>
          <center>
            <br/>
            <Typography variant="h4" component="h4">{this.state.machine.name}</Typography>
            <br/>
            <Typography variant="body1">{this.state.machine.description}</Typography>
            <br/><br/>
            <Tooltip disableFocusListener disableTouchListener title="Machine Settings"><IconButton disableFocusRipple aria-label="Machine settings" component="span" onClick={(e) => {e.stopPropagation(); this.editMachinePage()}} onFocus={(e) => e.stopPropagation()}><SettingsOutlined /></IconButton></Tooltip>
          </center>
          <div>
            <div style={splitDivs}>
              {states}
            </div>
            <div style={splitDivs}>
              <PieChart width={400} height={400}>
                <Pie
                  activeIndex={dataMapIndex}
                  data={data}
                  cx={200}
                  cy={200}
                  innerRadius={20}
                  outerRadius={120}
                  labelLine={false}
                  isAnimationActive={true}
                  animationDuration={1}
                  label={({cx, cy, midAngle, innerRadius, outerRadius, percent, index}) => {return data[index].name}}
                  dataKey="value">
                  {data.map((entry, index) => (<Cell key={`cell-${index}`} fill={index === dataMapIndex ? Colours.pieCharts.active : Colours.pieCharts.basic} />))}
                </Pie>
              </PieChart>
            </div>
          </div>
          <Dialog open={this.state.addStateDialogOpen} onClose={this.addStateDialogClose} aria-labelledby="form-dialog-title">
            <DialogTitle id="add-machine-dialog-title">Add State</DialogTitle>
            <DialogContent>
              <DialogContentText>Enter the name for the new State</DialogContentText>
              <TextField autoFocus margin="dense" id="add-state-name" onChange={this.addStateDialogStateNameChange} onKeyUp={this.addStateDialogAdd} label="State name" type="text" fullWidth/>
            </DialogContent>
            <DialogContent>
              <DialogContentText>Enter a description for the new State</DialogContentText>
              <TextField margin="dense" id="add-state-description" onChange={this.addStateDialogStateDescriptionChange} onKeyUp={this.addStateDialogAdd} label="State description (optional)" type="text" fullWidth/>
            </DialogContent>
            <DialogActions>
              <Button onClick={this.addStateDialogClose} variant="outlined" color="primary">Cancel</Button>
              <Button onClick={this.addStateDialogAdd} variant="contained" color="primary">Add</Button>
            </DialogActions>
          </Dialog>
        </div>
      )
    } else {
      return (
        <div className='mainBody'>
          <center>
            <h1>Loading...</h1>
          </center>
        </div>
      )
    }
  }
}

export default withRouter(withSnackbar(Machine))
