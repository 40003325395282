import React from 'react'
import { Switch, Route } from 'react-router-dom'
import './App.css'
import Banner from './Banner.js'
import Home from './Home.js'
import Profile from './Profile.js'
import Login from './Login.js'
import Machines from './Machines.js'
import Machine from './Machine.js'
import MachineConfig from './MachineConfig.js'

import AtomakiAPIClient from './AtomakiAPIClient.js'
import Cookies from 'universal-cookie'

const cookies = new Cookies()

class Main extends React.Component {
  render () {
    if (this.props.loggedIn) {
      return (
        <Switch>
          <Route exact path='/ui'><Home loggedIn={this.props.loggedIn} /></Route>
          <Route exact path='/ui/machines'><Machines utils={this.props.utils}/></Route>
          <Route exact path='/ui/machines/:machineId'><Machine utils={this.props.utils}/></Route>
          <Route exact path='/ui/machines/:machineId/config'><MachineConfig utils={this.props.utils}/></Route>
          <Route exact path='/ui/login' render={props => <Login {...props} />} />
          <Route exact path='/ui/logout'><Home loggedIn={this.props.loggedIn}/></Route>
          <Route exact path='/ui/profile'><Profile utils={this.props.utils}/></Route>
        </Switch>
      )
    } else {
      return (
        <Switch>
          <Route exact path='/ui'><Home utils={this.props.utils} loggedIn={this.props.loggedIn} /></Route>
          <Route exact path='/ui/login' render={props => <Login {...props} />} />
        </Switch>
      )
    }
  }
}

class App extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      username: cookies.get('user'),
      loggedIn: typeof cookies.get('user') === 'string'
    }
    this.atomakiAPIClient = new AtomakiAPIClient()
    this.utils = {
      atomakiAPIClient: this.atomakiAPIClient
    }
  }

  render () {
    return (
      <div className='app'>
        <Banner loggedIn={this.state.loggedIn} username={this.state.username} />
        <Main loggedIn={this.state.loggedIn} utils={this.utils} />
      </div>
    )
  }
}

export default App
