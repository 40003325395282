import React from 'react'
import Card from '@material-ui/core/Card'
import CardActionArea from '@material-ui/core/CardActionArea'
import CardContent from '@material-ui/core/CardContent'
import Typography from '@material-ui/core/Typography'
import { AddCircleOutlined } from '@material-ui/icons'
import Tooltip from '@material-ui/core/Tooltip';

import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'

import { withRouter } from 'react-router-dom'
import { withStyles } from '@material-ui/styles'
import { withSnackbar  } from 'notistack'
import Colours from './Colours.js'

const NavCard = withStyles(() => ({
  root: {
    maxWidth: 230,
    margin: '5px',
    display: 'inline-block',
    background: Colours.navigationCards.background,
    color: 'white'
  }
}))(Card)

class Machines extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      addMachineDialogOpen: false,
      addMachineDialogMachineName: '',
      addMachineDialogMachineDescription: '',
      machines: undefined
    }
    this.atomakiAPIClient = props.utils.atomakiAPIClient
    this.enqueueSnackbar = props.enqueueSnackbar

    this.refreshData = this.refreshData.bind(this)
    this.routeChange = this.routeChange.bind(this)
    this.addMachineDialogOpen = this.addMachineDialogOpen.bind(this)
    this.addMachineDialogClose = this.addMachineDialogClose.bind(this)
    this.addMachineDialogAdd = this.addMachineDialogAdd.bind(this)
    this.addMachineDialogMachineNameChange = this.addMachineDialogMachineNameChange.bind(this)
    this.addMachineDialogMachineDescriptionChange = this.addMachineDialogMachineDescriptionChange.bind(this)
  }

  addMachineDialogOpen () {
    this.setState({ addMachineDialogOpen: true })
  }

  addMachineDialogClose () {
    this.setState({ addMachineDialogOpen: false })
  }

  async addMachineDialogAdd (e) {
    if (!this.state.addMachineDialogOpen ||
      this.state.addMachineDialogMachineName.length === 0 ||
      (e.keyCode && e.keyCode !== 10 && e.keyCode !== 13)) {
      return
    }

    e.stopPropagation()
    this.addMachineDialogClose()
    const machineName = this.state.addMachineDialogMachineName
    const machineDescription = this.state.addMachineDialogMachineDescription
    try {
      let newMachine = await this.atomakiAPIClient.createMachine(machineName, machineDescription)
      this.refreshData()
      this.enqueueSnackbar('Machine ' + machineName + ' added', { variant: 'success' })
      this.setState({ addMachineDialogMachineName: '' })
      this.routeChange(`/ui/machines/${newMachine.id}`)
    } catch (e) {
      this.enqueueSnackbar('Failed to add machine ' + machineName, { variant: 'error' })
    }
  }

  addMachineDialogMachineNameChange (e) {
    this.setState({ addMachineDialogMachineName: e.target.value })
  }

  addMachineDialogMachineDescriptionChange (e) {
    this.setState({ addMachineDialogMachineDescription: e.target.value })
  }

  refreshData () {
    this.atomakiAPIClient.getMachines()
      .then(machines => {
        this.setState({
          machines: machines
        })
      }).catch(() => {
        this.enqueueSnackbar('Failed to fetch machines data', { variant: 'error' })
        this.setState({
          machines: undefined
        })
      })
  }

  componentDidMount () {
    this.refreshData()
  }

  routeChange = path => {
    this.props.history.push(path)
  }

  render () {
    let machines = <span>Loading...</span>
    if (this.state.machines) {
      machines = []
      this.state.machines.forEach(machine => {
        machines.push(<Tooltip title={machine.id} arrow key={machine.id}>
          <NavCard>
            <CardActionArea onClick={() => this.routeChange('/ui/machines/' + machine.id)}>
              <CardContent>
                <Typography variant="h5" component="h5">{machine.name}</Typography>
                <Typography variant="body1" component="p">{machine.description}</Typography>
              </CardContent>
            </CardActionArea>
          </NavCard>
        </Tooltip>)
      })
    }
    return (
      <div className='mainBody'>
        <center>
          <h1>Machines</h1>
        </center>
        <center>
          <Tooltip title="Add" key="add-new-machine-tooltip" arrow>
            <NavCard key="add-new-machine">
              <CardActionArea onClick={this.addMachineDialogOpen}>
                <CardContent>
                  <Typography variant="h5" component="h5">Create Machine</Typography>
                  <AddCircleOutlined />
                </CardContent>
              </CardActionArea>
            </NavCard>
          </Tooltip>
          <br/>
          {machines}
        </center>
        <Dialog open={this.state.addMachineDialogOpen} onClose={this.addMachineDialogClose} aria-labelledby="form-dialog-title">
          <DialogTitle id="add-machine-dialog-title">Add Machine</DialogTitle>
          <DialogContent>
            <DialogContentText>Enter the name for the new Machine</DialogContentText>
            <TextField autoFocus margin="dense" id="add-competition-seasonName" onChange={this.addMachineDialogMachineNameChange} onKeyUp={this.addMachineDialogAdd} label="Machine name" type="text" fullWidth/>
          </DialogContent>
          <DialogContent>
            <DialogContentText>Enter a description for the new Machine</DialogContentText>
            <TextField margin="dense" id="add-competition-seasonName" onChange={this.addMachineDialogMachineDescriptionChange} onKeyUp={this.addMachineDialogAdd} label="Machine description (optional)" type="text" fullWidth/>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.addMachineDialogClose} variant="outlined" color="primary">Cancel</Button>
            <Button onClick={this.addMachineDialogAdd} variant="contained" color="primary">Add</Button>
          </DialogActions>
        </Dialog>
      </div>
    )
  }
}

export default withRouter(withSnackbar(Machines))
