import React from 'react'

class Login extends React.Component {
  render () {
    const q = new URLSearchParams(this.props.location.search)
    let message = <div></div>
    let loginUrl = '/auth/login'
    if (q.get("e") === "1") {
      message = <div><br/><br/><span>login error!</span></div>
    }
    if (q.get("nextUrl")) {
      loginUrl = `/auth/login?nextUrl=${q.get("nextUrl")}`
    }
    return (
      <div className='loginback'>
        <br />
        <br />
        <div className='loginwrap'>
          <div id='login' className='login'>
            <form action={loginUrl} method='post'>
              <div className='headingBlock'>
                <div className='heading'>
                  <span className='heading'>Atomaki login</span>
                  {message}
                </div>
              </div>
              <div className='entryBlock'>
                <div className='entryField'>
                  <span>Username:</span>
                </div>
                <div className='entryField'>
                  <input id='username' name='username' className='loginCredentials' />
                </div>
              </div>
              <div className='entryBlock'>
                <div className='entryField'>
                  <span>Password:</span>
                </div>
                <div className='entryField'>
                  <input id='password' name='password' type='password' className='loginCredentials' />
                </div>
              </div>
              <div className='buttonBlock'>
                <input type='submit' value='Log in' className='loginButton' />
              </div>
            </form>
          </div>
        </div>
      </div>
    )
  }
}

export default Login
