import axios from 'axios'
import Cookies from 'universal-cookie'
const cookies = new Cookies()

class AtomakiAPIClient {
  async getMachines () {
    const headers = {
      'x-csrf-token': cookies.get('X-CSRF-Token')
    }
    return axios.get('/api/v1/machines', { headers: headers })
      .then(res => {
        return res.data
      })
  }

  async getMachine (machineId) {
    const headers = {
      'x-csrf-token': cookies.get('X-CSRF-Token')
    }
    return axios.get(`/api/v1/machines/${machineId}`, { headers: headers })
      .then(res => {
        return res.data
      })
  }

  async createMachine (machineName, machineDescription) {
    const headers = {
      'x-csrf-token': cookies.get('X-CSRF-Token'),
      'content-type': 'application/json'
    }
    return axios.post('/api/v1/machines', { name: machineName, description: machineDescription }, { headers: headers })
      .then(res => {
        return res.data
      })
  }

  async updateMachine (machineId, machineName, machineDescription) {
    const headers = {
      'x-csrf-token': cookies.get('X-CSRF-Token'),
      'content-type': 'application/json'
    }
    return axios.put(`/api/v1/machines/${machineId}`, { name: machineName, description: machineDescription }, { headers: headers })
      .then(res => {
        return res.data
      })
  }

  async deleteMachine (machineId) {
    const headers = {
      'x-csrf-token': cookies.get('X-CSRF-Token'),
      'content-type': 'application/json'
    }
    return axios.delete(`/api/v1/machines/${machineId}`, { headers: headers })
      .then(res => {
        return
      })
  }

  async createState (machineId, stateName, stateDescription) {
    const headers = {
      'x-csrf-token': cookies.get('X-CSRF-Token'),
      'content-type': 'application/json'
    }
    return axios.post(`/api/v1/machines/${machineId}/states`, { name: stateName, description: stateDescription }, { headers: headers })
      .then(res => {
        return res.data
      })
  }

  async updateState (machineId, stateId, stateName, stateDescription) {
    const headers = {
      'x-csrf-token': cookies.get('X-CSRF-Token'),
      'content-type': 'application/json'
    }
    return axios.put(`/api/v1/machines/${machineId}/states/${stateId}`, { name: stateName, description: stateDescription }, { headers: headers })
      .then(res => {
        return res.data
      })
  }

  async createTransition (machineId, targetStateId) {
    const headers = {
      'x-csrf-token': cookies.get('X-CSRF-Token'),
      'content-type': 'application/json'
    }
    return axios.post(`/api/v1/machines/${machineId}/transitions`, { targetStateId: targetStateId }, { headers: headers })
      .then(res => {
        return res.data
      })
  }

  async getProfile () {
    const headers = {
      'x-csrf-token': cookies.get('X-CSRF-Token'),
      'content-type': 'application/json'
    }
    return axios.get('/api/v1/account/profile', { headers: headers })
      .then(res => {
        return res.data
      })
  }

  async getAPIKeys () {
    const headers = {
      'x-csrf-token': cookies.get('X-CSRF-Token'),
      'content-type': 'application/json'
    }
    return axios.get('/api/v1/account/keys', { headers: headers })
      .then(res => {
        return res.data
      })
  }

  async createAPIKey (key_name) {
    const headers = {
      'x-csrf-token': cookies.get('X-CSRF-Token'),
      'content-type': 'application/json'
    }
    return axios.post('/api/v1/account/keys', {name: key_name}, { headers: headers })
      .then(res => {
        return res.data
      })
  }

  async updateAPIKey (key_id, key_name) {
    const headers = {
      'x-csrf-token': cookies.get('X-CSRF-Token'),
      'content-type': 'application/json'
    }
    return axios.put(`/api/v1/account/keys/${key_id}`, {name: key_name}, { headers: headers })
      .then(res => {
        return
      })
  }

  async deleteAPIKey (key_id) {
    const headers = {
      'x-csrf-token': cookies.get('X-CSRF-Token'),
      'content-type': 'application/json'
    }
    return axios.delete(`/api/v1/account/keys/${key_id}`, { headers: headers })
      .then(res => {
        return
      })
  }

  async getWebhooks (machineId) {
    const headers = {
      'x-csrf-token': cookies.get('X-CSRF-Token')
    }
    return axios.get(`/api/v1/machines/${machineId}/webhooks`, { headers: headers })
      .then(res => {
        return res.data
      })
  }

  async createWebhook (machineId, webhookName, webhookUrl, webhookScope) {
    const headers = {
      'x-csrf-token': cookies.get('X-CSRF-Token'),
      'content-type': 'application/json'
    }
    return axios.post(`/api/v1/machines/${machineId}/webhooks`, { name: webhookName, url: webhookUrl, scope: webhookScope }, { headers: headers })
      .then(res => {
        return res.data
      })
  }

  async updateWebhook (machineId, webhookId, webhookName, webhookUrl, webhookScope) {
    const headers = {
      'x-csrf-token': cookies.get('X-CSRF-Token'),
      'content-type': 'application/json'
    }
    return axios.put(`/api/v1/machines/${machineId}/webhooks/${webhookId}`, { name: webhookName, url: webhookUrl, scope: webhookScope }, { headers: headers })
      .then(res => {
        return res.data
      })
  }

  async deleteWebhook (machineId, webhookId) {
    const headers = {
      'x-csrf-token': cookies.get('X-CSRF-Token'),
      'content-type': 'application/json'
    }
    return axios.delete(`/api/v1/machines/${machineId}/webhooks/${webhookId}`, { headers: headers })
      .then(res => {
        return
      })
  }

  async updatePassword (newPassword) {
    const headers = {
      'x-csrf-token': cookies.get('X-CSRF-Token'),
      'content-type': 'application/json'
    }
    return axios.post('/authup/password', { password: newPassword }, { headers: headers })
      .then(res => {
        return res.data
      })
  }
}

export default AtomakiAPIClient
