import React from 'react'

import { AddCircleOutlineOutlined, DeleteOutlined, EditOutlined, FilterNoneOutlined, SaveOutlined, Visibility, VisibilityOff } from '@material-ui/icons'
import { withRouter } from 'react-router-dom'
import { withSnackbar  } from 'notistack'
import { withStyles } from '@material-ui/styles'

import Button from '@material-ui/core/Button'
import Colours from './Colours.js'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import FormControl from '@material-ui/core/FormControl'
import IconButton from '@material-ui/core/IconButton'
import InputAdornment from '@material-ui/core/InputAdornment'
import InputLabel from '@material-ui/core/InputLabel'
import OutlinedInput from '@material-ui/core/OutlinedInput'
import TextField from '@material-ui/core/TextField'
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography'

const CreateButton = withStyles({
  root: {
    boxShadow: 'none',
    textTransform: 'none',
    fontSize: 16,
    padding: '6px 12px',
    border: '1px solid',
    lineHeight: 1.5,
    color: Colours.white,
    backgroundColor: Colours.green.main,
    borderColor: Colours.green.main,
    '&:hover': {
      backgroundColor: Colours.green.L1,
      borderColor: Colours.green.L1,
      boxShadow: 'none',
    },
    '&:active': {
      boxShadow: 'none',
      backgroundColor: Colours.green.L2,
      borderColor: Colours.green.L2,
    },
  },
})(Button)

const AddCircleOutlineOutlinedWhite = withStyles({
  root: {
    color: '#eeeeee',
  },
})(AddCircleOutlineOutlined)

const EditOutlinedBlue = withStyles({
  root: {
    color: Colours.blue.L2,
  },
})(EditOutlined)

const DeleteOutlinedRed = withStyles({
  root: {
    color: Colours.red.L1,
  },
})(DeleteOutlined)

const SaveOutlinedWhite = withStyles({
  root: {
    color: '#eeeeee',
  },
})(SaveOutlined)

class Profile extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      addAPIKeyDialogOpen: false,
      addAPIKeyDialogKeyName: '',
      newAPIKeyDialogOpen: false,
      editAPIKeyDialogOpen: false,
      editAPIKeyDialogKeyId: '',
      editAPIKeyDialogKeyName: '',
      deleteAPIKeyDialogOpen: false,
      deleteAPIKeyDialogKeyId: '',
      deleteAPIKeyDialogName: '',
      newAPIKey: '',
      profile: {},
      apiKeys: [],
      password: '',
      showPassword: false
    }
    this.atomakiAPIClient = props.utils.atomakiAPIClient
    this.enqueueSnackbar = props.enqueueSnackbar

    this.refreshData = this.refreshData.bind(this)
    this.toggleShowPassword = this.toggleShowPassword.bind(this)
    this.passwordChange = this.passwordChange.bind(this)
    this.updatePassword = this.updatePassword.bind(this)
    this.addAPIKeyDialogOpen = this.addAPIKeyDialogOpen.bind(this)
    this.addAPIKeyDialogClose = this.addAPIKeyDialogClose.bind(this)
    this.addAPIKeyDialogAdd = this.addAPIKeyDialogAdd.bind(this)
    this.addAPIKeyDialogKeyNameChange = this.addAPIKeyDialogKeyNameChange.bind(this)
    this.editAPIKeyDialogOpen = this.editAPIKeyDialogOpen.bind(this)
    this.editAPIKeyDialogClose = this.editAPIKeyDialogClose.bind(this)
    this.editAPIKeyDialogEdit = this.editAPIKeyDialogEdit.bind(this)
    this.editAPIKeyDialogKeyNameChange = this.editAPIKeyDialogKeyNameChange.bind(this)
    this.deleteAPIKeyDialogOpen = this.deleteAPIKeyDialogOpen.bind(this)
    this.deleteAPIKeyDialogClose = this.deleteAPIKeyDialogClose.bind(this)
    this.deleteAPIKeyDialogDelete = this.deleteAPIKeyDialogDelete.bind(this)
    this.newAPIKeyDialogClose = this.newAPIKeyDialogClose.bind(this)
    this.copyAPIKeyToClipboard = this.copyAPIKeyToClipboard.bind(this)
  }

  toggleShowPassword () {
    this.setState({ showPassword: !this.state.showPassword })
  };

  passwordChange (e) {
    this.setState({ password: e.target.value })
  }

  async updatePassword () {
    try {
      await this.atomakiAPIClient.updatePassword(this.state.password)
      this.enqueueSnackbar('Password changed', { variant: 'success' })
      this.setState({ password: '' })
    } catch (e) {
      this.enqueueSnackbar('Failed to change the password', { variant: 'error' })
    }
  }

  addAPIKeyDialogOpen () {
    this.setState({ addAPIKeyDialogOpen: true })
  }

  addAPIKeyDialogClose () {
    this.setState({ addAPIKeyDialogOpen: false })
  }

  async addAPIKeyDialogAdd (e) {
    if (e.keyCode && e.keyCode !== 10 && e.keyCode !== 13) {
      return
    }
    e.stopPropagation()
    try {
      const newKey = await this.atomakiAPIClient.createAPIKey(this.state.addAPIKeyDialogKeyName)
      this.setState({
        addAPIKeyDialogKeyName: '',
        addAPIKeyDialogOpen: false,
        newAPIKeyDialogOpen: true,
        newAPIKey: newKey.apiKey
      })
      this.enqueueSnackbar('API Key created', { variant: 'success' })
      await this.refreshData()
    } catch (e) {
      this.enqueueSnackbar('Failed to add new API Key', { variant: 'error' })
    }
  }

  addAPIKeyDialogKeyNameChange (e) {
    this.setState({ addAPIKeyDialogKeyName: e.target.value })
  }

  newAPIKeyDialogClose () {
    this.setState({
      newAPIKeyDialogOpen: false,
      newAPIKey: ''
    })
  }

  async copyAPIKeyToClipboard () {
    try {
      await navigator.clipboard.writeText(this.state.newAPIKey)
      this.enqueueSnackbar('API Key copied to clipboard', { variant: 'success' })
    } catch (e) {
      this.enqueueSnackbar('Failed to copy new API Key to clipboard', { variant: 'error' })
    }
  }

  editAPIKeyDialogOpen (keyId, keyName) {
    this.setState({
      editAPIKeyDialogOpen: true,
      editAPIKeyDialogKeyId: keyId,
      editAPIKeyDialogKeyName: keyName,
    })
  }

  editAPIKeyDialogClose () {
    this.setState({ editAPIKeyDialogOpen: false })
  }

  async editAPIKeyDialogEdit (e) {
    if (e.keyCode && e.keyCode !== 10 && e.keyCode !== 13) {
      return
    }
    e.stopPropagation()
    this.editAPIKeyDialogClose()
    try {
      await this.atomakiAPIClient.updateAPIKey(this.state.editAPIKeyDialogKeyId, this.state.editAPIKeyDialogKeyName)
      this.setState({
        editAPIKeyDialogKeyId: '',
        editAPIKeyDialogKeyName: ''
      })
      this.enqueueSnackbar('API Key updated', { variant: 'success' })
      await this.refreshData()
    } catch (e) {
      this.enqueueSnackbar('Failed to update API Key', { variant: 'error' })
    }
    //
  }

  editAPIKeyDialogKeyNameChange (e) {
    this.setState({ editAPIKeyDialogKeyName: e.target.value })
  }

  deleteAPIKeyDialogOpen (keyId, keyName) {
    this.setState({
      deleteAPIKeyDialogOpen: true,
      deleteAPIKeyDialogKeyId: keyId,
      deleteAPIKeyDialogName: keyName
    })
  }

  deleteAPIKeyDialogClose () {
    this.setState({ deleteAPIKeyDialogOpen: false })
  }

  async deleteAPIKeyDialogDelete (e) {
    if (e.keyCode && e.keyCode !== 10 && e.keyCode !== 13) {
      return
    }
    this.deleteAPIKeyDialogClose()
    try {
      await this.atomakiAPIClient.deleteAPIKey(this.state.deleteAPIKeyDialogKeyId)
      this.setState({
        deleteAPIKeyDialogKeyId: '',
        deleteAPIKeyDialogName: ''
      })
      this.enqueueSnackbar('API Key deleted', { variant: 'success' })
      await this.refreshData()
    } catch (e) {
      this.enqueueSnackbar('Failed to delete API Key', { variant: 'error' })
    }
  }

  async refreshData () {
    try {
      const profile = await this.atomakiAPIClient.getProfile()
      const apiKeys = await this.atomakiAPIClient.getAPIKeys()
      this.setState({
        profile: profile,
        apiKeys: apiKeys
      })
    } catch (e) {
      this.enqueueSnackbar('Failed to fetch user profile', { variant: 'error' })
    }
  }

  componentDidMount () {
    this.refreshData()
  }

  render () {
    let body = <div className='mainBody'>
      <center>
        <br />
        <br />
        <p>Loading...</p>
      </center>
    </div>
    if (this.state.profile.username) {
      let apiKeys = []
      this.state.apiKeys.forEach(apiKey => {
        let createdDate = new Date(apiKey.created)
        let lastUsedDate = 'never'
        if (apiKey.lastUsed !== -1) {
          lastUsedDate = new Date(apiKey.lastUsed)
        }
        let created = `created: ${createdDate}`
        let lastUsed = `last used: ${lastUsedDate}`
        apiKeys.push(<div  key={apiKey.id} className="settingsSection">
          <div className="settingsRow">
            <div className="settingsRowBody">
              {apiKey.name}
              <br/>
              <br/>
              <span className="annotation">{created}</span><br />
              <span className="annotation">{lastUsed}</span>
            </div>
            <div className="settingsRowButtons">
              <div className="settingsRowButton">
                <Tooltip disableFocusListener disableTouchListener title="Edit Key"><IconButton disableFocusRipple aria-label="Edit Key" component="span" onClick={(e) => {e.stopPropagation(); this.editAPIKeyDialogOpen(apiKey.id, apiKey.name)}} onFocus={(e) => e.stopPropagation()}><EditOutlinedBlue /></IconButton></Tooltip>
              </div>
              <div className="settingsRowButton">
                <Tooltip disableFocusListener disableTouchListener title="Delete Key"><IconButton disableFocusRipple aria-label="Delete Key" component="span" onClick={(e) => {e.stopPropagation(); this.deleteAPIKeyDialogOpen(apiKey.id, apiKey.name)}} onFocus={(e) => e.stopPropagation()}><DeleteOutlinedRed /></IconButton></Tooltip>
              </div>
            </div>
          </div>
        </div>)
      })
      let deleteMessage = `Are you sure you want to delete "${this.state.deleteAPIKeyDialogName}"?`
      let joinDate = this.state.profile.created ? new Date(this.state.profile.created) : ''
      body = <div className='mainBody'>
        <br />
        <br />
        <br />
        <div className="settings">
          <div className="settingsHeader">
            <div className="settingsHeaderBody">
              <Typography variant="inherit" component="span">User Profile</Typography>
            </div>
          </div>
          <div className="settingsSection">
            <div className="settingsRow">
              <div className="settingsRowBody">
                <Typography variant="inherit" component="span">Joined</Typography>
                <br />
                <Typography variant="body1" component="span">{joinDate.toString()}</Typography>
              </div>
            </div>
            <div className="settingsRow">
              <div className="settingsRowBody">
                <Typography variant="inherit" component="span">Username</Typography>
                <br />
                <Typography variant="body1" component="span">{this.state.profile.username}</Typography>
              </div>
            </div>
            <div className="settingsRow">
              <div className="settingsRowBody">
                <FormControl variant="outlined" fullWidth>
                  <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
                  <OutlinedInput
                    fullWidth
                    id="outlined-adornment-password"
                    type={this.state.showPassword ? 'text' : 'password'}
                    value={this.state.password}
                    onChange={this.passwordChange}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={this.toggleShowPassword}
                          edge="end"
                        >
                          {this.state.showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    }
                    labelWidth={70}
                  />
                </FormControl>
              </div>
              <div className="settingsRowButtons">
                <CreateButton onClick={(e) => {e.stopPropagation(); this.updatePassword()}} onFocus={(e) => e.stopPropagation()}>Update&nbsp;password</CreateButton>
              </div>
            </div>
          </div>
        </div>
        <div className="settings">
          <div className="settingsHeader">
            <div className="settingsHeaderBody">
              <Typography variant="inherit" component="span">API Keys</Typography>
            </div>
            <div className="settingsHeaderButton">
              <CreateButton onClick={(e) => {e.stopPropagation(); this.addAPIKeyDialogOpen()}} onFocus={(e) => e.stopPropagation()}><AddCircleOutlineOutlinedWhite />&nbsp;Create&nbsp;Key</CreateButton>
            </div>
          </div>
          <div className="settingsSection">
            <div className="settingsRow">
              <div className="settingsRowBody">
                <Typography variant="body2" component="span">API Keys enable calls direct to the Atomaki API</Typography>
              </div>
            </div>
          </div>
          {apiKeys}
        </div>
        <Dialog open={this.state.addAPIKeyDialogOpen} onClose={this.addAPIKeyDialogClose} aria-labelledby="form-dialog-title">
          <DialogTitle id="add-key-dialog-title">Add API Key</DialogTitle>
          <DialogContent>
            <DialogContentText>Enter the details for the new API Key</DialogContentText>
            <TextField autoFocus margin="dense" id="add-key-name" onChange={this.addAPIKeyDialogKeyNameChange} onKeyUp={this.addAPIKeyDialogAdd} label="API Key name" type="text" fullWidth/>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.addAPIKeyDialogClose} variant="outlined" color="primary">Cancel</Button>
            <Button onClick={this.addAPIKeyDialogAdd} variant="contained" color="primary">Add</Button>
          </DialogActions>
        </Dialog>
        <Dialog open={this.state.editAPIKeyDialogOpen} onClose={this.editAPIKeyDialogClose} aria-labelledby="form-dialog-title">
          <DialogTitle id="edit-key-dialog-title">Edit API Key</DialogTitle>
          <DialogContent>
            <DialogContentText>Enter the new details for the API Key</DialogContentText>
            <TextField autoFocus margin="dense" id="edit-key-name" onChange={this.editAPIKeyDialogKeyNameChange} onKeyUp={this.editAPIKeyDialogAdd} label="API Key name" type="text" value={this.state.editAPIKeyDialogKeyName} fullWidth />
          </DialogContent>
          <DialogActions>
            <Button onClick={this.editAPIKeyDialogClose} variant="outlined" color="primary">Cancel</Button>
            <Button onClick={this.editAPIKeyDialogEdit} variant="contained" color="primary">Update</Button>
          </DialogActions>
        </Dialog>
        <Dialog open={this.state.deleteAPIKeyDialogOpen} onClose={this.deleteAPIKeyDialogClose} aria-labelledby="form-dialog-title">
          <DialogTitle id="delete-match-dialog-title">Delete API Key</DialogTitle>
          <DialogContent>
            <DialogContentText>{deleteMessage}</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.deleteAPIKeyDialogClose} variant="outlined" color="primary">Cancel</Button>
            <Button onClick={this.deleteAPIKeyDialogDelete} autoFocus onKeyUp={this.deleteAPIKeyDialogDelete} variant="contained" color="secondary" disableElevation>Delete</Button>
          </DialogActions>
        </Dialog>
        <Dialog open={this.state.newAPIKeyDialogOpen} onClose={this.newAPIKeyDialogClose} aria-labelledby="form-dialog-title">
          <DialogTitle id="new-key-dialog-title">New API Key</DialogTitle>
          <DialogContent>
            <DialogContentText>Make sure you copy and store your new API key somewhere secure. You will not be able to retreive the key value once this dialog closes</DialogContentText>
            <span>{this.state.newAPIKey}</span>
            <Tooltip disableFocusListener disableTouchListener title="Copy API Key"><IconButton disableFocusRipple aria-label="Copy API Key" component="span" onClick={(e) => {e.stopPropagation(); this.copyAPIKeyToClipboard()}} onFocus={(e) => e.stopPropagation()}><FilterNoneOutlined /></IconButton></Tooltip>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.newAPIKeyDialogClose} variant="outlined" color="primary">Close</Button>
          </DialogActions>
        </Dialog>
      </div>
    }
    return (
      <div>
        {body}
      </div>
    )
  }
}

export default withRouter(withSnackbar(Profile))
