import React from 'react'
import { NavLink } from 'react-router-dom'
import AppBar from '@material-ui/core/AppBar'
import Button from '@material-ui/core/Button'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import IconButton from '@material-ui/core/IconButton'
import SvgIcon from '@material-ui/core/SvgIcon'
import PersonIcon from '@material-ui/icons/Person'
import { withStyles } from '@material-ui/core/styles'
import packageJson from '../package.json'
import Colours from './Colours.js'

const styles = theme => ({
  root: {
    flexGrow: 1,
  },
  bannerTitle: {
    width: '100%',
    textAlign: 'center',
  },
  eyesButton: {
    marginRight: theme.spacing(1),
    color: Colours.banner.text,
  },
  userButton: {
    marginRight: theme.spacing(2),
    color: Colours.banner.text,
    backgroundColor: Colours.userButton.background,
    '&:hover': {
      color: Colours.userButton.hover.text,
      backgroundColor: Colours.userButton.hover.background,
    },
  },
  machinesButton: {
    color: Colours.machinesButton.text,
    backgroundColor: Colours.machinesButton.background,
    textTransform: 'none',
    '&:hover': {
      color: Colours.machinesButton.hover.text,
      backgroundColor: Colours.machinesButton.hover.background,
    },
  },
  version: {
    flexGrow: 1,
    fontSize: 12,
    color: Colours.banner.versionText,
    marginTop: '5px'
  },
  appBar: {
    'background-color': Colours.banner.background,
  }
})

class Banner extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      loggedIn: props.loggedIn,
      logout: props.logout,
      username: props.username,
      anchorElement: null
    }
    this.openMenu = this.openMenu.bind(this)
    this.closeMenu = this.closeMenu.bind(this)
  }

  openMenu = (event) => {
    this.setState({ anchorElement: event.currentTarget })
  };

  closeMenu = () => {
    this.setState({ anchorElement: null })
  };

  render () {
    const { classes } = this.props
    let userButton
    let machinesButton
    if (this.props.loggedIn) {
      machinesButton = <div>
        <Button href="/ui/machines" className={classes.machinesButton}>Machines</Button>
      </div>
      userButton = <div>
        <IconButton edge="start" className={classes.userButton} aria-label="profile-menu-in" aria-controls="profile-menu-in" aria-haspopup="true" onClick={this.openMenu}>
          <PersonIcon />
        </IconButton>
        <Menu getContentAnchorEl={null}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          id="profile-menu-in" anchorEl={this.state.anchorElement} keepMounted open={Boolean(this.state.anchorElement)} onClose={this.closeMenu}>
          <NavLink to='/ui/machines' className="flatLink">
            <MenuItem onClick={this.closeMenu}>Machines</MenuItem>
          </NavLink>
          <NavLink to='/ui/profile' className="flatLink">
            <MenuItem onClick={this.closeMenu}>Profile</MenuItem>
          </NavLink>
          <a href='/auth/logout' className="flatLink">
            <MenuItem onClick={this.closeMenu}>
              Logout
            </MenuItem>
          </a>
        </Menu>
      </div>
    } else {
      machinesButton = <div></div>
      userButton = <div>
        <IconButton edge="start" className={classes.userButton} aria-label="profile-menu-out" aria-controls="profile-menu-out" aria-haspopup="true" onClick={this.openMenu}>
          <PersonIcon />
        </IconButton>
        <Menu getContentAnchorEl={null}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }} transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          id="profile-menu-out" anchorEl={this.state.anchorElement} keepMounted open={Boolean(this.state.anchorElement)} onClose={this.closeMenu}>
          <NavLink to='/ui/login' className="flatLink">
            <MenuItem onClick={this.closeMenu}>Login</MenuItem>
          </NavLink>
        </Menu>
      </div>
    }

    return (
      <AppBar position="static" className={classes.appBar}>
        <Toolbar>
          {machinesButton}
          <div className={classes.bannerTitle}>
            <NavLink to='/ui'>
              <IconButton edge="start" className={classes.eyesButton} color="inherit" aria-label="menu">
                <SvgIcon>
                  <defs>
                   <clipPath id="a">
                    <circle cx="34.118" cy="58.883" r="10.229" d="M 44.347159,58.883411 A 10.229438,10.229438 0 0 1 34.117722,69.112849 10.229438,10.229438 0 0 1 23.888284,58.883411 10.229438,10.229438 0 0 1 34.117722,48.653974 10.229438,10.229438 0 0 1 44.347159,58.883411" display="none" fill="#e4e4e4"/>
                    <path className="powerclip" d="m5.9301 29.743h48.238v48.238h-48.238zm38.417 29.141a10.229 10.229 0 0 0-10.229-10.229 10.229 10.229 0 0 0-10.229 10.229 10.229 10.229 0 0 0 10.229 10.229 10.229 10.229 0 0 0 10.229-10.229" fill="#e4e4e4"/>
                   </clipPath>
                   <clipPath id="b">
                    <circle cx="34.118" cy="58.883" r="10.229" d="M 44.347159,58.883411 A 10.229438,10.229438 0 0 1 34.117722,69.112849 10.229438,10.229438 0 0 1 23.888284,58.883411 10.229438,10.229438 0 0 1 34.117722,48.653974 10.229438,10.229438 0 0 1 44.347159,58.883411" display="none" fill="#e4e4e4"/>
                    <path className="powerclip" d="m5.9301 29.743h48.238v48.238h-48.238zm38.417 29.141a10.229 10.229 0 0 0-10.229-10.229 10.229 10.229 0 0 0-10.229 10.229 10.229 10.229 0 0 0 10.229 10.229 10.229 10.229 0 0 0 10.229-10.229" fill="#e4e4e4"/>
                   </clipPath>
                  </defs>
                  <g stroke="#fff">
                   <path transform="matrix(0 .20972 -.20972 0 31.286 5.6979)" d="m45.168 53.862a15.119 15.119 0 0 1-15.119 15.119 15.119 15.119 0 0 1-15.119-15.119 15.119 15.119 0 0 1 15.119-15.119 15.119 15.119 0 0 1 15.119 15.119z" clipPath="url(#a)" fill="#fff" strokeWidth="8"/>
                   <path transform="scale(-1)" d="m-17.902-17.156a7.8369 7.8369 0 0 1 10.099-1.4566" fill="none" strokeLinecap="round" strokeWidth="2.4401"/>
                   <path transform="matrix(.20972 0 0 .20972 -2.2923 .70391)" d="m45.168 53.862a15.119 15.119 0 0 1-15.119 15.119 15.119 15.119 0 0 1-15.119-15.119 15.119 15.119 0 0 1 15.119-15.119 15.119 15.119 0 0 1 15.119 15.119z" clipPath="url(#b)" fill="#fff" strokeWidth="8"/>
                   <path d="m4.5164 9.6649a7.8369 7.8369 0 0 1 4.6639-4.975 7.8369 7.8369 0 0 1 6.7961 0.56259" fill="none" strokeLinecap="round" strokeWidth="2.4401"/>
                   <path transform="scale(-1)" d="m-19.064-15.398a7.8369 7.8369 0 0 1 3.8438-3.7451" fill="none" strokeWidth="2.4401"/>
                  </g>
                </SvgIcon>
              </IconButton>
            </NavLink>
            <Typography variant="h6" component="span">Atomaki</Typography>
            <Typography variant="body2"  component="span" className={classes.version}>&nbsp;&nbsp;v{packageJson.version}</Typography>
          </div>
          {userButton}
        </Toolbar>
      </AppBar>
    )
  }
}

export default withStyles(styles)(Banner)
