
const Colours = {
  // https://paletton.com/#uid=55-0I0kkIhFfIqOiVlpmGe9oOaz
  red2: {
    L2: '#CE6973',
    L1: '#A5434C',
    main: '#883038',
    D1: '#6D2027',
    D2: '#511218'
  },
  // https://paletton.com/#uid=52P0I0kkIhFfIqOiVlpmGe9oOaz
  green2: {
    L2: '#57AB57',
    L1: '#388838',
    main: '#287128',
    D1: '#1A5A1A',
    D2: '#0F430F'
  },
  // https://paletton.com/#uid=53F0I0kkIhFfIqOiVlpmGe9oOaz
  blue2: {
    L2: '#4E678D',
    L1: '#344C70',
    main: '#263C5D',
    D1: '#1A2E4A',
    D2: '#102037'
  },
  // https://paletton.com/#uid=5000I0koEl7kSslmVp3rGgvu7bp
  red: {
    L2: '#E24F4F',
    L1: '#C83939',
    main: '#A82727',
    D1: '#831212',
    D2: '#5B0505'
  },
  // https://paletton.com/#uid=52P0I0koEl7kSslmVp3rGgvu7bp
  green: {
    L2: '#3FB53F',
    L1: '#2DA02D',
    main: '#1F871F',
    D1: '#0E690E',
    D2: '#044904'
  },
  blue: {
    L2: '#396B91',
    L1: '#2A5B80',
    main: '#1E4A6C',
    D1: '#103755',
    D2: '#07243A'
  },
  white: '#FFFFFF',
  banner: {
    // background: '#3C91E6',
    background: '#883039',
    text: '#ffffff',
    // versionText: '#bbaa99',
    versionText: '#A5434C',
  },
  machinesButton: {
    background: '#A5434C',
    text: '#EEEEEE',
    hover: {
      background: '#CE6973',
      text: '#FFFFFF',
    },
  },
  userButton: {
    background: '#883038',
    text: '#EEEEEE',
    hover: {
      background: '#A5434C',
      text: '#FFFFFF',
    },
  },
  navigationCards: {
    // background: '#3C91E6',
    // backgroundActive: '#33BF3C;'
    background: '#883039',
    backgroundActive: '#33BF3C;'
  },
  pieCharts: {
    // basic: '#3C91E6',
    basic: '#883039',
    active: '#347e8d',
  },

  button: {
    add: ''
  }
}

export default Colours
