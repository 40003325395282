import React from 'react'
import Typography from '@material-ui/core/Typography'
import { AddCircleOutlineOutlined, ArrowBackOutlined, DeleteOutlined, EditOutlined, SaveOutlined } from '@material-ui/icons'
import Tooltip from '@material-ui/core/Tooltip';

import Button from '@material-ui/core/Button'
import Colours from './Colours.js'
import IconButton from '@material-ui/core/IconButton'
import TextField from '@material-ui/core/TextField'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import FormControl from '@material-ui/core/FormControl'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import InputLabel from '@material-ui/core/InputLabel'

import { withRouter } from 'react-router-dom'
import { withSnackbar  } from 'notistack'
import { withStyles } from '@material-ui/styles'

const CreateButton = withStyles({
  root: {
    boxShadow: 'none',
    textTransform: 'none',
    fontSize: 16,
    padding: '6px 12px',
    border: '1px solid',
    lineHeight: 1.5,
    color: Colours.white,
    backgroundColor: Colours.green.main,
    borderColor: Colours.green.main,
    '&:hover': {
      backgroundColor: Colours.green.L1,
      borderColor: Colours.green.L1,
      boxShadow: 'none',
    },
    '&:active': {
      boxShadow: 'none',
      backgroundColor: Colours.green.L2,
      borderColor: Colours.green.L2,
    },
  },
})(Button)

const DeleteButton = withStyles({
  root: {
    boxShadow: 'none',
    textTransform: 'none',
    fontSize: 16,
    padding: '6px 12px',
    border: '1px solid',
    lineHeight: 1.5,
    color: Colours.white,
    backgroundColor: Colours.red.main,
    borderColor: Colours.red.main,
    '&:hover': {
      backgroundColor: Colours.red.L1,
      borderColor: Colours.red.L1,
      boxShadow: 'none',
    },
    '&:active': {
      boxShadow: 'none',
      backgroundColor: Colours.red.L2,
      borderColor: Colours.red.L2,
    },
  },
})(Button)

const AddCircleOutlineOutlinedWhite = withStyles({
  root: {
    color: '#eeeeee',
  },
})(AddCircleOutlineOutlined)

const EditOutlinedBlue = withStyles({
  root: {
    color: Colours.blue.L2,
  },
})(EditOutlined)

const DeleteOutlinedRed = withStyles({
  root: {
    color: Colours.red.L1,
  },
})(DeleteOutlined)

const DeleteOutlinedWhite = withStyles({
  root: {
    color: '#eeeeee',
  },
})(DeleteOutlined)

const SaveOutlinedWhite = withStyles({
  root: {
    color: '#eeeeee',
  },
})(SaveOutlined)

class MachineConfig extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      machineConfigNameDialog: '',
      machineConfigDescriptionDialog: '',
      addWebhookDialogOpen: false,
      addWebhookDialogName: '',
      addWebhookDialogURL: '',
      addWebhookDialogScope: 'machine',
      editWebhookDialogOpen: false,
      editWebhookDialogWebhookId: '',
      editWebhookDialogName: '',
      editWebhookDialogUrl: '',
      editWebhookDialogScope: '',
      deleteWebhookDialogOpen: false,
      deleteWebhookDialogWebhookId: '',
      deleteMachineDialogOpen: false,
      machine: undefined,
      webhooks: [],
    }
    this.machineId = this.props.match.params.machineId
    this.atomakiAPIClient = props.utils.atomakiAPIClient
    this.enqueueSnackbar = props.enqueueSnackbar

    this.refreshData = this.refreshData.bind(this)
    this.saveMachineConfig = this.saveMachineConfig.bind(this)
    this.machineConfigNameChange = this.machineConfigNameChange.bind(this)
    this.machineConfigDescriptionChange = this.machineConfigDescriptionChange.bind(this)
    this.addWebhookDialogOpen = this.addWebhookDialogOpen.bind(this)
    this.addWebhookDialogClose = this.addWebhookDialogClose.bind(this)
    this.addWebhookDialogAdd = this.addWebhookDialogAdd.bind(this)
    this.addWebhookDialogNameChange = this.addWebhookDialogNameChange.bind(this)
    this.addWebhookDialogUrlChange = this.addWebhookDialogUrlChange.bind(this)
    this.addWebhookDialogScopeChange = this.addWebhookDialogScopeChange.bind(this)
    this.editWebhookDialogOpen = this.editWebhookDialogOpen.bind(this)
    this.editWebhookDialogClose = this.editWebhookDialogClose.bind(this)
    this.editWebhookDialogEdit = this.editWebhookDialogEdit.bind(this)
    this.editWebhookDialogNameChange = this.editWebhookDialogNameChange.bind(this)
    this.editWebhookDialogUrlChange = this.editWebhookDialogUrlChange.bind(this)
    this.editWebhookDialogScopeChange = this.editWebhookDialogScopeChange.bind(this)
    this.deleteWebhookDialogOpen = this.deleteWebhookDialogOpen.bind(this)
    this.deleteWebhookDialogClose = this.deleteWebhookDialogClose.bind(this)
    this.deleteWebhookDialogDelete = this.deleteWebhookDialogDelete.bind(this)
    this.deleteMachineDialogOpen = this.deleteMachineDialogOpen.bind(this)
    this.deleteMachineDialogClose = this.deleteMachineDialogClose.bind(this)
    this.deleteMachineDialogDelete = this.deleteMachineDialogDelete.bind(this)
    this.routeChange = this.routeChange.bind(this)
  }

  async saveMachineConfig () {
    try {
      await this.atomakiAPIClient.updateMachine(this.machineId, this.state.machineConfigNameDialog, this.state.machineConfigDescriptionDialog)
      this.enqueueSnackbar('Machine updated', { variant: 'success' })
      await this.refreshData()
    } catch (e) {
      this.enqueueSnackbar('Failed to update Machine config', { variant: 'error' })
    }
  }

  machineConfigNameChange (e) {
    this.setState({ machineConfigNameDialog: e.target.value })
  }

  machineConfigDescriptionChange (e) {
    this.setState({ machineConfigDescriptionDialog: e.target.value })
  }

  addWebhookDialogOpen () {
    this.setState({ addWebhookDialogOpen: true })
  }

  addWebhookDialogClose () {
    this.setState({ addWebhookDialogOpen: false })
  }

  async addWebhookDialogAdd (e) {
    if (e.keyCode && e.keyCode !== 10 && e.keyCode !== 13) {
      return
    }
    e.stopPropagation()
    try {
      await this.atomakiAPIClient.createWebhook(this.machineId, this.state.addWebhookDialogName, this.state.addWebhookDialogUrl, this.state.addWebhookDialogScope)
      this.setState({
        addWebhookDialogName: '',
        addWebhookDialogURL: '',
        addWebhookDialogScope: 'machine',
        addWebhookDialogOpen: false
      })
      this.enqueueSnackbar('Webhook created', { variant: 'success' })
      await this.refreshData()
    } catch (e) {
      this.enqueueSnackbar('Failed to add new Webhook', { variant: 'error' })
    }
  }

  addWebhookDialogNameChange (e) {
    this.setState({ addWebhookDialogName: e.target.value })
  }

  addWebhookDialogUrlChange (e) {
    this.setState({ addWebhookDialogUrl: e.target.value })
  }

  addWebhookDialogScopeChange (e) {
    this.setState({ addWebhookDialogScope: e.target.value })
  }

  editWebhookDialogOpen (webhookId, webhookName, webhookUrl, webhookScope) {
    this.setState({
      editWebhookDialogOpen: true,
      editWebhookDialogWebhookId: webhookId,
      editWebhookDialogName: webhookName,
      editWebhookDialogUrl: webhookUrl,
      editWebhookDialogScope: webhookScope
    })
  }

  editWebhookDialogClose () {
    this.setState({ editWebhookDialogOpen: false })
  }

  async editWebhookDialogEdit (e) {
    if (e.keyCode && e.keyCode !== 10 && e.keyCode !== 13) {
      return
    }
    e.stopPropagation()
    this.editWebhookDialogClose()
    try {
      await this.atomakiAPIClient.updateWebhook(this.machineId, this.state.editWebhookDialogWebhookId, this.state.editWebhookDialogName, this.state.editWebhookDialogUrl, this.state.editWebhookDialogScope )
      this.setState({
        editWebhookDialogOpen: false,
        editWebhookDialogWebhookId: '',
        editWebhookDialogName: '',
        editWebhookDialogUrl: '',
        editWebhookDialogScope: ''
      })
      this.enqueueSnackbar('Webhook updated', { variant: 'success' })
      await this.refreshData()
    } catch (e) {
      this.enqueueSnackbar('Failed to update webhook', { variant: 'error' })
    }
  }

  editWebhookDialogNameChange (e) {
    this.setState({ editWebhookDialogName: e.target.value })
  }

  editWebhookDialogUrlChange (e) {
    this.setState({ editWebhookDialogUrl: e.target.value })
  }

  editWebhookDialogScopeChange (e) {
    this.setState({ editWebhookDialogScope: e.target.value })
  }

  deleteWebhookDialogOpen (webhookId) {
    this.setState({
      deleteWebhookDialogOpen: true,
      deleteWebhookDialogWebhookId: webhookId
    })
  }

  deleteWebhookDialogClose () {
    this.setState({ deleteWebhookDialogOpen: false })
  }

  async deleteWebhookDialogDelete (e) {
    if (e.keyCode && e.keyCode !== 10 && e.keyCode !== 13) {
      return
    }
    this.deleteWebhookDialogClose()
    try {
      await this.atomakiAPIClient.deleteWebhook(this.machineId, this.state.deleteWebhookDialogWebhookId)
      this.setState({
        deleteWebhookDialogWebhookId: ''
      })
      this.enqueueSnackbar('Webhook deleted', { variant: 'success' })
      await this.refreshData()
    } catch (e) {
      this.enqueueSnackbar('Failed to delete Webhook ', { variant: 'error' })
    }
  }

  deleteMachineDialogOpen () {
    this.setState({ deleteMachineDialogOpen: true })
  }

  deleteMachineDialogClose () {
    this.setState({ deleteMachineDialogOpen: false })
  }

  deleteMachineDialogDelete (e) {
    if (e.keyCode && e.keyCode !== 10 && e.keyCode !== 13) {
      return
    }
    this.deleteMachineDialogClose()
    this.atomakiAPIClient.deleteMachine(this.state.machine.id)
      .then(
        () => {
          this.enqueueSnackbar('Machine deleted', { variant: 'success' })
          this.routeChange('/ui/machines')
        },
        (err) => {
          this.enqueueSnackbar('Failed to delete Machine', { variant: 'error' })
        })
  }

  async refreshData () {
    try {
      const machine_webhook = await Promise.all([
        this.atomakiAPIClient.getMachine(this.machineId),
        this.atomakiAPIClient.getWebhooks(this.machineId)
      ])
      const machine = machine_webhook[0]
      const webhooks = machine_webhook[1]
      this.setState({
        machineConfigNameDialog: machine.name,
        machineConfigDescriptionDialog: machine.description,
        machine: machine,
        webhooks: webhooks
      })
    } catch (e) {
      this.enqueueSnackbar('Failed to fetch machine data', { variant: 'error' })
      this.setState({
        machine: undefined,
        webhooks: [],
      })
    }
  }

  componentDidMount () {
    this.refreshData()
  }

  returnToMachinePage () {
    this.routeChange(`/ui/machines/${this.machineId}`)
  }

  routeChange = path => {
    this.props.history.push(path)
  }

  render () {
    let body = <div className='mainBody'>
      <center>
        <br />
        <br />
        <p>Loading...</p>
      </center>
    </div>
    if (this.state.machine) {
      let webhooks = []
      this.state.webhooks.forEach(webhook => {
        let lastCalled = 'never called'
        let lastRC = 'no recorded result'
        if (webhook.lastCallTime !== -1) {
          lastCalled = `last called: ${new Date(webhook.lastCallTime)}`
        }
        if (webhook.lastCallResult !== 0) {
          lastRC = `last call result: ${webhook.lastCallResult}`
        }
        webhooks.push(<div key={webhook.id} className="settingsSection">
          <div className="settingsRow">
            <div className="settingsRowBody">
              {webhook.name}<br/>
              {webhook.url}<br/>
              {webhook.scope}<br/>
              <span className="annotation">{lastCalled}</span><br />
              <span className="annotation">{lastRC}</span>
            </div>
            <div className="settingsRowButtons">
              <div className="settingsRowButton">
                <Tooltip disableFocusListener disableTouchListener title="Edit webhook"><IconButton disableFocusRipple aria-label="Edit webhook" component="span" onClick={(e) => {e.stopPropagation(); this.editWebhookDialogOpen(webhook.id, webhook.name, webhook.url, webhook.scope)}} onFocus={(e) => e.stopPropagation()}><EditOutlinedBlue /></IconButton></Tooltip>
              </div>
              <div className="settingsRowButton">
                <Tooltip disableFocusListener disableTouchListener title="Delete webhook"><IconButton disableFocusRipple aria-label="Delete webhook" component="span" onClick={(e) => {e.stopPropagation(); this.deleteWebhookDialogOpen(webhook.id)}} onFocus={(e) => e.stopPropagation()}><DeleteOutlinedRed /></IconButton></Tooltip>
              </div>
            </div>
          </div>
        </div>)
      })

      body = <div className='mainBody'>
        <br />
        <Tooltip disableFocusListener disableTouchListener title="Close Settings"><IconButton disableFocusRipple aria-label="Close settings" component="span" onClick={(e) => {e.stopPropagation(); this.returnToMachinePage()}} onFocus={(e) => e.stopPropagation()}><ArrowBackOutlined /></IconButton></Tooltip>
        <div className="settings">
          <div className="settingsHeader">
            <div className="settingsHeaderBody">
              <Typography variant="inherit" component="span">Machine settings</Typography>
            </div>
            <div className="settingsHeaderButton">
              <DeleteButton onClick={(e) => {e.stopPropagation(); this.deleteMachineDialogOpen()}} onFocus={(e) => e.stopPropagation()}><DeleteOutlinedWhite />&nbsp;Delete&nbsp;Machine</DeleteButton>
            </div>
          </div>
          <div className="settingsSection">
            <div className="settingsRow">
              <div className="settingsRowBody">
                <Typography variant="inherit" component="span">Machine name</Typography>
                <TextField margin="dense" id="machine-config-username" onChange={this.machineConfigNameChange} type="text" variant="outlined" defaultValue={this.state.machine.name} fullWidth />
              </div>
            </div>
            <div className="settingsRow">
              <div className="settingsRowBody">
                <Typography variant="inherit" component="span">Machine description</Typography>
                <TextField margin="dense" id="machine-config-username" onChange={this.machineConfigDescriptionChange} type="text" variant="outlined" defaultValue={this.state.machine.description} fullWidth />
              </div>
            </div>
            <div className="settingsRow">
              <div className="settingsRowBody"></div>
              <div className="settingsRowButtons">
                <CreateButton onClick={(e) => {e.stopPropagation(); this.saveMachineConfig()}} onFocus={(e) => e.stopPropagation()}><SaveOutlinedWhite />&nbsp;Save</CreateButton>
              </div>
            </div>
          </div>
        </div>
        <div className="settings">
          <div className="settingsHeader">
            <div className="settingsHeaderBody">
              <Typography variant="inherit" component="span">Webhooks</Typography>
            </div>
            <div className="settingsHeaderButton">
              <CreateButton onClick={(e) => {e.stopPropagation(); this.addWebhookDialogOpen()}} onFocus={(e) => e.stopPropagation()}><AddCircleOutlineOutlinedWhite />&nbsp;Create&nbsp;Webhook</CreateButton>
            </div>
          </div>
          <div className="settingsSection">
            <div className="settingsRow">
              <div className="settingsRowBody">
                <Typography variant="body2" component="span">Webhooks are called each time a Machine changes state</Typography>
              </div>
            </div>
          </div>
          {webhooks}
        </div>
        <Dialog open={this.state.addWebhookDialogOpen} onClose={this.addWebhookDialogClose} aria-labelledby="form-dialog-title">
          <DialogTitle id="add-webhook-dialog-title">Add Webhook</DialogTitle>
          <DialogContent>
            <DialogContentText>Enter the details for the new Webhook</DialogContentText>
            <TextField autoFocus margin="dense" id="add-webhook-name" onChange={this.addWebhookDialogNameChange} onKeyUp={this.addWebhookDialogAdd} label="Webhook name" type="text" fullWidth/>
            <TextField margin="dense" id="add-webhook-url" onChange={this.addWebhookDialogUrlChange} onKeyUp={this.addWebhookDialogAdd} label="Webhook url" type="text" fullWidth/>
            <FormControl>
              <InputLabel shrink id="add-webhook-scope-label">Scope</InputLabel>
              <Select labelId="add-webhook-scope-label" label="Scope" id="add-webhook-scope" value={this.state.addWebhookDialogScope} onChange={this.addWebhookDialogScopeChange}>
                <MenuItem value="machine" key="1">machine</MenuItem>
                <MenuItem value="metadata" key="2">metadata</MenuItem>
                <MenuItem value="state" key="3">state</MenuItem>
                <MenuItem value="none" key="4">none</MenuItem>
              </Select>
            </FormControl>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.addWebhookDialogClose} variant="outlined" color="primary">Cancel</Button>
            <Button onClick={this.addWebhookDialogAdd} variant="contained" color="primary">Add</Button>
          </DialogActions>
        </Dialog>
        <Dialog open={this.state.editWebhookDialogOpen} onClose={this.editWebhookDialogClose} aria-labelledby="form-dialog-title">
          <DialogTitle id="edit-webhook-dialog-title">Edit Webhook</DialogTitle>
          <DialogContent>
            <DialogContentText>Enter the new details for the Webhook</DialogContentText>
            <TextField autoFocus margin="dense" id="edit-webhook-name" onChange={this.editWebhookDialogNameChange} onKeyUp={this.editWebhookDialogAdd} label="Webhook name" type="text" value={this.state.editWebhookDialogName} fullWidth />
            <TextField margin="dense" id="edit-webhook-url" onChange={this.editWebhookDialogUrlChange} onKeyUp={this.editWebhookDialogAdd} label="Webhook url" type="text" value={this.state.editWebhookDialogUrl} fullWidth/>
            <FormControl>
              <InputLabel shrink id="edit-webhook-scope-label">Scope</InputLabel>
              <Select labelId="edit-webhook-scope-label" label="Scope" id="edit-webhook-scope" value={this.state.editWebhookDialogScope} onChange={this.editWebhookDialogScopeChange}>
                <MenuItem value="machine" key="1">machine</MenuItem>
                <MenuItem value="metadata" key="2">metadata</MenuItem>
                <MenuItem value="state" key="3">state</MenuItem>
                <MenuItem value="none" key="4">none</MenuItem>
              </Select>
            </FormControl>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.editWebhookDialogClose} variant="outlined" color="primary">Cancel</Button>
            <Button onClick={this.editWebhookDialogEdit} variant="contained" color="primary">Update</Button>
          </DialogActions>
        </Dialog>
        <Dialog open={this.state.deleteWebhookDialogOpen} onClose={this.deleteWebhookDialogClose} aria-labelledby="form-dialog-title">
          <DialogTitle id="delete-match-dialog-title">Delete Webhook</DialogTitle>
          <DialogContent>
            <DialogContentText>Are you sure you want to delete the webhook?</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.deleteWebhookDialogClose} variant="outlined" color="primary">Cancel</Button>
            <Button onClick={this.deleteWebhookDialogDelete} autoFocus onKeyUp={this.deleteWebhookDialogDelete} variant="contained" color="secondary" disableElevation>Delete</Button>
          </DialogActions>
        </Dialog>
        <Dialog open={this.state.deleteMachineDialogOpen} onClose={this.deleteDialogDialogClose} aria-labelledby="form-dialog-title">
          <DialogTitle id="delete-machine-dialog-title">Delete Machine</DialogTitle>
          <DialogContent>
            <DialogContentText>Are you sure you want to delete the Machine?</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.deleteMachineDialogClose} variant="outlined" color="primary">Cancel</Button>
            <Button onClick={this.deleteMachineDialogDelete} autoFocus onKeyUp={this.deleteMachineDialogDelete} variant="contained" color="secondary">Delete</Button>
          </DialogActions>
        </Dialog>
      </div>
    }
    return (
      <div className='mainBody'>
        {body}
      </div>
    )
  }
}

export default withRouter(withSnackbar(MachineConfig))
